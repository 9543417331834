<template>
    <Card
        ref="refCards"
        v-for="(card, index) in cards"
        :key="index"
        :index="index"
        :active="active && index === cardIndex"
        :visible="active"
        :motionActive="motionActive"
        :rotation="card.rotation"
        :flip="card.flip"
        :revealed="card.revealed"
        :canReveal="canReveal"
        :zoom="card.zoom"
        :revealProgress="card.revealProgress"
        :revealAngle="card.revealAngle"
        :colorPalette="colorPalette"
        :data="card.data"
    />
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';

    import { usePane, useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import Card from './Card.vue';

    const props = defineProps({
        cardIndex: { default: 0 },
        cards: { default: [] },
        colorPalette: { default: [] },
    });

    const active = ref(false);
    const motionActive = ref(false);
    const canReveal = ref(false);

    const zGap = ref(3);
    const yGap = ref(0);

    const refCards = ref([]);

    const { object } = useThreeObject(null, { name: 'CardWrapper' });

    const addToParent = (parent) => {
        parent.add(object);
    };

    onMounted(() => {
        // window.cardsObject = object;

        refCards.value.forEach((card, index) => {
            card.object.position.z = index * -zGap.value;
        });
    });

    const show = ({ delay: mainDelay = 0 } = {}) => {
        const tl = gsap.timeline({
            delay: mainDelay,
            onStart: () => {
                active.value = true;
            },
            onComplete: () => {
                canReveal.value = true;
                motionActive.value = true;
            },
        });

        tl.fromTo(object.position, { z: 0 }, { z: 4, duration: 1.2, ease: 'power2.inOut' }, 0);
        // tl.add(() => (canReveal.value = true), 0.8);

        setCardPositions();

        refCards.value.forEach((card, index) => {
            card.show({ delay: mainDelay + index * 1 });
        });
    };

    const hide = () => {
        motionActive.value = false;

        const tl = gsap.timeline({
            onComplete: () => {
                active.value = false;
                reset();
            },
        });
        tl.to(object.position, { z: 0, duration: 1.2, ease: 'power2.inOut' }, 0);

        refCards.value.forEach((card, index) => {
            tl.to(card.object.position, { z: -3, duration: 1, ease: 'power2.inOut' }, 0);
            card.hide();
        });
    };

    const setCardIndex = (val, prevVal) => {
        motionActive.value = false;

        if (active.value) {
            const tl = gsap.timeline({ onComplete: () => (motionActive.value = true) });

            setCardPositions(tl);
        } else {
            setCardPositions();
        }
    };

    const setCardPositions = (tl) => {
        refCards.value.forEach((card, index) => {
            const newIndex = index - props.cardIndex;
            const isPastFront = newIndex < 0;

            const y = isPastFront ? -3 : newIndex * yGap.value;
            const z = Math.max(newIndex, 0) * -zGap.value;

            if (tl) {
                tl.to(card.object.position, { y, z, duration: 1.2, ease: 'power3.inOut' }, 0);
            } else {
                gsap.set(card.object.position, { y, z });
            }
        });
    };

    const reset = () => {
        gsap.killTweensOf(object.position);
        object.position.set(0, 0, 0);
        canReveal.value = false;

        refCards.value.forEach((card) => {
            card.object.rotation.set(0, 0, 0);
            card.reset();
        });
    };

    const setProps = () => {
        refCards.value.forEach((card, index) => {
            card.object.position.y = index * yGap.value;
            card.object.position.z = index * -zGap.value;
        });
    };

    watch([zGap, yGap], setProps);
    watch(() => props.cardIndex, setCardIndex);

    usePane(
        [
            { name: 'zGap', value: zGap, options: { min: 0, max: 5, step: 0.01 } },
            { name: 'yGap', value: yGap, options: { min: 0, max: 2, step: 0.01 } },
        ],
        { title: 'Cards' }
    );

    defineExpose({
        addToParent,
        show,
        hide,
    });
</script>
